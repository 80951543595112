@import 'reset';

$green: #1ed45f;
$gray: #221f1f;

body {
  background: $gray;
  color: #ffffff;
  font-family: 'Lato', sans-serif;
}

p {
  font-size: 15px;
  font-weight: 300;
  line-height: 1.6;
  margin-bottom: 1em;
}

a {
  text-decoration: none !important ;
  color: inherit !important;
}

a.link {
  color: $green !important;
  text-decoration: underline !important;
}

b {
  font-weight: bold;
}

#app {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: calc(100vw - calc(100vw - 100%));
  grid-template-areas: 'header' 'main' 'footer';
  min-height: 100vh;

  header {
    grid-area: header;
  }
  main {
    grid-area: main;
  }
  footer {
    grid-area: footer;
  }
}

.Header {
  background: linear-gradient(to bottom, black 0%, transparent 100%);
  display: flex;
  flex-direction: row;
  justify-content: flex;
  align-items: center;
  gap: 3rem;
  padding: 40px;
  width: 100vw;
  box-sizing: border-box;
  z-index: 5;

  .Navigation {
    flex-grow: 1;
  }
}

.Logo {
  width: 151px;
  height: 41px;
  color: $green;
  transition: all 0.125s ease;
  font-weight: bold;
  font-size: 2rem;
  display: flex;
  align-items: center;

  img {
    margin-right: 0.7rem;
  }

  &:hover {
    color: lighten($green, 20);
    cursor: pointer;
    margin-top: 2px;
  }
}

.Navigation ul {
  display: flex;
  height: 37px;
  align-items: center;
  padding: 0 10px;

  & li {
    font-weight: 400;
    padding: 7px 10px;
    font-size: 14px;
    transition: all 0.25s ease;
    border-radius: 3px;
  }

  & li a {
    text-decoration: none;
    color: inherit;
  }

  & li:hover {
    background: $green;
    color: $gray;
    cursor: pointer;
  }
}

.Button {
  background: transparent;
  display: flex;
  height: 44px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #ffffff;
  padding: 20px;
  box-sizing: border-box;
  border: 2px solid rgba(245, 245, 241, 0.2);
  border-radius: 44px;
  font-size: 14px;
  font-weight: 600;
  transition: border 0.125s ease, background 0.125s ease;

  &:hover {
    border: 2px solid #f5f5f1;
  }
  &[data-primary='true'] {
    border: 2px solid$green;
  }
  &[data-primary='true']:hover {
    background: $green;
    color: $gray;
  }
}

footer {
  padding: 0 3rem;
  margin-bottom: 0.5rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: calc(100vw - calc(100vw - 100%));

  & > * + * {
    margin-left: 0.5rem !important;
  }

  .text-lightgray {
    color: #aaa;
  }

  img#smapiot-logo {
    position: relative;
    top: 4px;
    left: -2px;
  }
  a:hover {
    border-bottom: 1px dashed gray;
  }
  .copyright {
    font-size: 0.7rem;
    margin-left: -5px;
  }
  p {
    margin: 0;
  }
}
